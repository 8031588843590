@font-face {
  font-family: 'open-sans';
  src: local('open-sans'),
    url(../assets/fonts/muzeum/open-sans.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'open-sans-bold';
  src: local('open-sans-bold'),
    url(../assets/fonts/muzeum/open-sans-bold.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'segoe';
  src: local('segoe'), url(../assets/fonts/cc/Segoe-UI.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'segoe-bold';
  src: local('segoe-bold'),
    url(../assets/fonts/cc/SegoeUIBlack.ttf) format('truetype');
  font-display: swap;
}

body iframe {
  display: none !important;
  z-index: 0 !important;
}

.wallet {
  width: 100vw;
  position: relative;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 100%;
  min-height: calc(100vh - 40px);
  position: relative;
  // margin-bottom: 100px;
  // padding-bottom: 20px;
}

.wallet-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  .card-holder {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 0 auto;
    position: relative;
    justify-content: center;
    margin-bottom: 80px;

    .cards {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;
      position: relative;
      margin-bottom: 120px;

      .no-cards {
        font-size: 15px;
        margin-top: 10px;
        margin-left: auto;
        margin-right: auto;
        font-family: 'segoe';
        margin-bottom: 150px;
      }
    }
  }

  .card {
    position: relative;
    width: calc((100% - (30px * 3)) / 4);
    margin: 0;

    cursor: pointer;
    .card-image {
      width: 100%;
      margin: 0;
    }
    .not-owned-card-image {
      width: 100%;
      opacity: 0.5;
    }
    .gift {
      position: absolute;
      top: 95px;
      left: 60px;
      z-index: 2;
      width: 70px;
    }
    .coin {
      position: absolute;
      width: 200px;
      height: 280px;
      border-radius: 4px;
    }
    .card-number {
      background-color: black;
      color: white;
      position: absolute;
      top: 10px;
      left: 10px;
      width: 20px;
      height: 20px;
      z-index: 1;
    }
  }
  .card img {
    max-width: 100%;
  }

  @media (max-width: 1400px) {
    margin: 0 auto;
    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;
      width: calc(100vw - 120px);
      margin-bottom: 80px;
    }
  }

  @media (max-width: 1200px) {
    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;
      width: calc(100vw - 60px);
      margin-bottom: 80px;
    }

    .card {
      width: calc((100% - (30px * 2)) / 3);
    }
  }

  @media (max-width: 992px) {
    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-bottom: 70px;
      .cards {
        padding: 0 30px;
        // margin-bottom: 0;
      }
    }

    .card {
      width: calc((100% - 30px) / 2);
    }
  }

  @media (max-width: 768px) {
    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin-bottom: 70px;
    }
  }

  @media (max-width: 576px) {
    max-width: 90%;
    margin: 0 auto;
    padding-bottom: 140px;
    .card-holder {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      margin: 0 auto;

      .cards {
        padding: 0;
        margin-bottom: 0;
      }
    }
    .card {
      width: 100%;
      padding: 0;
    }
    .card-image {
      width: 100%;
    }
  }
}

.background-img {
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0;
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

.badge-image {
  position: absolute;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.nav-back {
  // z-index: 10;
  // position: absolute;
  width: 100px;
  height: 25px;
  // top: 35px;
  // left: 100px;
  // cursor: pointer;
}

.logo-holder {
  display: flex;
  justify-content: center;
  margin-top: 22px;
}

.logo-div {
  margin-left: 0px;
}

.logo {
  width: 300px;
}

.address {
  text-align: center;
  font-family: 'segoe';
  margin-bottom: 150px;
}

.address-title {
  font-size: 30px;
  line-height: 25px;
  color: white;
  margin: 10px;
}

.address .wallet-address {
  //font-family: "open-sans";
  font-style: normal;
  // font-weight: 600;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  //color: #5a513f;
  margin: auto 20px;

  margin-top: 8px;
  margin-bottom: 16px;
}

.modal {
  position: sticky;
  width: 100vw;
  z-index: 10;
  bottom: 0px;
  .modal-overlay {
    opacity: 1;
    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #65532f;
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      height: 266px;
      // height: 306px;
      color: #fdf7f7;
      div {
        width: fit-content;
        text-align: center;
      }
      .bonus-title {
        font-size: 28px;
        line-height: 25px;
        margin-top: 37px;
        margin-bottom: 45px;
      }
      .bonus {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 50px;
      }
      .bonus-detail {
        font-size: 18px;
        line-height: 22px;
      }
      .modal-close {
        position: absolute;
        width: 30px;
        top: 30px;
        right: 28px;
      }
    }
  }
}

.grand-gift {
  width: 150px;
  height: 60px;
}

.modal-gift {
  width: 200px;
  position: absolute;
  top: 10px;
  left: 90px;
  z-index: 2;
}

.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 200px);
  max-width: 1320px;
  margin: 0 auto;
}

.crest-button {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  gap: 12px;
  // width: 604px;
  width: 100%;
  max-width: 368px;
  height: 42px;
  background: #ba5b2d;
  border: 1px solid #ba5b2d;
  border-radius: 40px;
  margin-bottom: 30px;

  font-family: 'open-sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.crest-arrow {
  position: absolute;
  right: 15px;
}

.crest-text {
  margin: 0;
  margin-left: -10px;
}

.crest-shield {
  margin-top: 9px;
}

@media (min-width: 1441px) {
  .wallet .header {
    margin: 24px auto;
    margin: 24px auto;
    max-width: 1320px;
  }
}

@media (max-width: 1416px) {
  .wallet .wallet-container {
    // max-width: 1304px;
    margin: 0 auto;
  }
}

@media (max-width: 430px) {
  .wallet-container .card-holder {
    max-width: 100%;
  }

  .wallet-container .card .coin {
    top: 35px;
    left: 10px;
    width: 76px;
    height: 73px;
  }
  .wallet-container .card .gift {
    top: 45px;
    left: 25px;
    width: 45px;
  }
  .logo {
    width: 172px;
  }

  .wallet-container .address .address-title {
    font-size: 22px;
    margin: 5px;
  }

  .modal .modal-overlay .modal-content div {
    width: 246px;
  }
  .modal .modal-overlay .modal-content .bonus-title {
    font-size: 22px;
    margin-bottom: 20px;
  }
  .modal .modal-overlay .modal-content .bonus {
    font-size: 16px;
    margin-bottom: 22px;
  }
  .modal .modal-overlay .modal-content .bonus-detail {
    font-size: 14px;
  }
  .modal-content .modal-close {
    width: 24px;
    top: 38px;
    right: 28px;
  }
}

@media (max-width: 768px) {
  .logo {
    width: 200px;
  }
  .nav-back {
    width: 50px;
    height: 15px;
  }
  .grand-gift {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 430px) {
  .logo {
    width: 172px;
  }
  .nav-back {
    width: 20px;
    height: 15px;
    margin-top: 20px;
  }
  .grand-gift {
    width: 32px;
    height: 32px;
    margin-top: 10px;
  }
  .header-container {
    width: calc(100% - 80px);
  }
}

@media (max-width: 375px) {
  .header-container {
    width: calc(100% - 40px);
  }
}

.tabs-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 20px;
  margin-bottom: 24px;
  background-color: rgb(90 81 63 / 8%);
  //background: rgba(255, 255, 255, 0.08);
  cursor: pointer;
  padding: 2px;
  .tab {
    color: #999999;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    padding: 6px 13.17px;
    white-space: nowrap;
    //font-family: "open-sans";
    //background-color: rgba(186, 91, 45, 0.1);
    font-family: 'segoe-bold';
  }
  // #osszes {
  //   background-color: rgba(186, 91, 45, 0.1);
  // }
  .selected-tab {
    background-color: rgba(186, 91, 45, 0.1);
    // color: #ba5b2d;
    //background: rgba(0, 213, 255, 0.1);
    //color: #00d5ff;
    color: #ba5b2d;
  }
}

@media (max-width: 320px) {
  .tabs-holder .tab {
    padding: 6px;
  }
}

.wallet-title {
  margin-top: 40px;
  margin-bottom: 32px;
  text-align: center;
}

.wallet-title h1 {
  // font-family: "open-sans-bold";
  font-family: 'segoe-bold';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 576px) {
    font-size: 20px;
    line-height: 32px;
  }
}

.wallet-title p {
  margin: 8px 20px;
  // font-weight: 600;
  font-weight: 400;
  margin-bottom: 0;
  font-family: 'segoe';

  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 24px;
  }
}

.card {
  margin: 8px;
  margin-bottom: 30px;
}

.card-title {
  // width: 159px;
  //height: 40px;
  // font-family: "open-sans-bold";
  // text-align: center;
  font-family: 'segoe-bold';
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  margin-top: 4px;
  margin-bottom: 0;
  @media (max-width: 800px) {
    text-align: left;
  }
}

.wallet-scanner-button {
  //position: sticky;
  height: 50px;
  width: 100%;
  border: none;
  border-radius: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  // background: #ffffff;
  // box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);
  background: #00d5ff;
  box-shadow: 0px -4px 12px rgba(0, 0, 0, 0.15);

  //text
  // font-family: "open-sans";
  font-family: 'segoe-bold';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  //color: #ba5b2d;
}

.sticky-button-holder {
  position: fixed;
  bottom: 0;
  width: 100vw;
}

.address-link {
  //color: #5a513f;
  font-family: 'open-sans';
  font-style: normal;
  font-size: 12px;
}

.cardpage-title {
  // font-family: "open-sans";
  font-family: 'segoe-bold';
  font-size: 20px;
  font-weight: 800;
  line-height: 32px;
  letter-spacing: 0em;
  text-align: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.single-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;
  font-size: 30px;
  line-height: 22px;
  .title-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: 24px;
    position: relative;
    width: 100%;
    gap: 16px;
    .close-btn {
      position: absolute;
      right: 22px;
      top: 6px;
      background-color: transparent;
      border: none;
      z-index: 10;
      width: 24px;
      height: 24px;
    }
    h1 {
      margin: 0;
      // -webkit-text-stroke: 1px white;
    }
  }
  .card-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1320px;
    margin: 0 auto;
    img {
      // height: 335px;
      // width: 335px;
      // margin-bottom: 16px;
      width: 90%;
      margin-bottom: 16px;
      max-width: 400px;
      max-height: 400px;
    }
    .cardpage-desc {
      font-family: 'segoe';
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: 0em;
      text-align: center;
      color: white;
      margin: 0 20px;
      margin-bottom: 150px;
      white-space: pre-line;
      max-width: 616px;
      margin-top: 35px;
      a {
        //color: #00d5ff;
        color: #d1c3bc;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 1320px) {
    .card-description {
      //max-width: 400px;
      // width: 95%;
      width: 100%;
      img {
        width: 90%;
      }
      .cardpage-desc {
        max-width: 100%;
      }
    }
    .arrows-holder {
      .arrow {
        width: 20px;
        height: 20px;
      }
    }

    .notOwned {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 6px;
      // margin-bottom: 17px;
      position: absolute;
      top: 65px;
      left: 75px;
      .notOwned-text {
        font-family: 'open-sans';
        font-size: 12px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        color: #ff5300;
        text-transform: uppercase;
        margin: 0;
      }
      img {
        width: 20px;
        height: 20px;
      }
    }
  }

  .notOwned {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6px;
    // margin-bottom: 17px;
    position: absolute;
    top: 65px;
    left: 44%;
    .notOwned-text {
      font-family: 'open-sans';
      font-size: 12px;
      font-weight: 700;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
      color: #ff5300;
      text-transform: uppercase;
      margin: 0;
    }
    img {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1918px) {
    .notOwned {
      left: 41.5%;
    }
  }

  @media (max-width: 1536px) {
    .notOwned {
      left: 40%;
    }
  }

  @media (max-width: 1440px) {
    .notOwned {
      left: 40%;
    }
  }

  @media (max-width: 1024px) {
    .notOwned {
      left: 35%;
    }
  }

  @media (max-width: 768px) {
    .notOwned {
      left: 31%;
    }
  }

  @media (max-width: 500px) {
    .notOwned {
      left: 21%;
    }
  }

  @media (max-width: 425px) {
    .notOwned {
      left: 16%;
    }
  }

  @media (max-width: 375px) {
    .notOwned {
      position: absolute;
      top: 65px;
      left: 11%;
    }
  }

  .single-card .close-btn:hover {
    cursor: pointer;
  }

  .loader {
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    position: absolute;
    right: calc(50% - 50px);
  }

  @media (max-width: 425px) {
    .loader {
      left: 38%;
    }
  }

  .arrows-holder {
    display: flex;
    flex-direction: row;
    gap: 12px;
    .arrow {
      width: 20px;
      height: 20px;
    }
  }

  .slick-prev {
    left: 48% !important;
    top: 58% !important;
    z-index: 1000 !important;
  }
  .slick-next {
    right: 48% !important;
    top: 58% !important;
    z-index: 1000 !important;
  }

  @media (max-width: 1918px) {
    .slick-prev {
      left: 47.5% !important;
      top: 62% !important;
    }
    .slick-next {
      right: 47.5% !important;
      top: 62% !important;
    }
  }

  @media (max-width: 1536px) {
    .slick-prev {
      left: 47.5% !important;
      top: 58% !important;
    }
    .slick-next {
      right: 47.5% !important;
      top: 58% !important;
    }
  }

  @media (max-width: 1024px) {
    .slick-prev {
      left: 47.5% !important;
      top: 61% !important;
    }
    .slick-next {
      right: 47.5% !important;
      top: 61% !important;
    }
  }

  @media (max-width: 768px) {
    .slick-prev {
      left: 47% !important;
      top: 58% !important;
    }
    .slick-next {
      right: 47% !important;
      top: 58% !important;
    }
  }

  @media (max-width: 576px) {
    .slick-prev {
      left: 45.5% !important;
      top: 56% !important;
    }
    .slick-next {
      right: 45.5% !important;
      top: 56% !important;
    }
  }

  @media (max-width: 425px) {
    .slick-prev {
      left: 44% !important;
      top: 51.5% !important;
    }
    .slick-next {
      right: 44% !important;
      top: 51.5% !important;
    }
  }

  @media (max-width: 375px) {
    .slick-prev {
      left: 44% !important;
      top: 47.5% !important;
    }
    .slick-next {
      right: 44% !important;
      top: 47.5% !important;
    }
  }

  .slick-slider.slick-initialized {
    width: 100%;
  }
  .slick-arrow.slick-prev {
    background: #5a513f;
  }
  .slick-arrow.slick-next {
    background: #5a513f;
  }
}
